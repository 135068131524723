import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor() { }

  private loaderSubject = new Subject<boolean>();
  loaderState = this.loaderSubject.asObservable();

  showLoader(): void {    
    this.loaderSubject.next(true);    
  }
  hideLoader(): void{
    this.loaderSubject.next(false);
  }

}
