import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class ToastrService {

  constructor(private messageService : MessageService) { }
  showSuccessMessage(successMessage: string) {
    this.messageService.add({
      severity: 'success',
      detail: successMessage,
      summary: 'Success'
    })
  }

  showErrorMessage(errorMessage: string) {
    this.messageService.add({
      severity: 'error',
      detail: errorMessage,
      summary: 'Error'
    })
  }

  showWarningMessage(warningMessage: string) {
    this.messageService.add({
      severity: 'warn',
      detail: warningMessage,
      summary: 'warn'
    })
  }

  showInfoMessage(infoMessage: string) {
    this.messageService.add({
      severity: 'info',
      detail: infoMessage,
      summary: 'info'
    })
  }

}
