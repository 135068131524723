import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { ToastrService } from './toastr.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  messages!: string;
  constructor(
    private router: Router,
    private toastrService: ToastrService
  ) { }

  handleError(error : any, messageObj ?: any , isShow : boolean | undefined = true) {
    if (!messageObj || !messageObj.errorMessage) {
      switch (error.status) {
        case 401:
          {
            this.messages = 'unauthorizedAccess';
            if (!localStorage.getItem('token')) {
              this.router.navigate(['/unauthorizedAccess']);
            }
            break;
          }
        case 500:
          {
            if (error && error.error.ResponseException) {
              this.messages =
                (error.error.ResponseException.ValidationErrors && error.error.ResponseException.ValidationErrors[0])
                  ? 'Something went wrong.'
                  : error.error.ResponseException.ExceptionMessage
                    ? error.error.ResponseException.ExceptionMessage
                    : 'Something went wrong.';
            } else {
              this.messages = 'Something went wrong.';
            }
            break;
          }
        case error.status >= 400 && error.status <= 499:
          this.messages = 'Something went wrong.';
          break;
        default:
          this.messages = 'Something went wrong.';
          break;
      }
    } else {
      this.messages = messageObj.errorMessage;
    }
    if ((!messageObj || messageObj.showMessage === undefined || messageObj.showMessage) && isShow) {
      if(typeof window !== 'undefined')
      this.toastrService.showErrorMessage(this.messages)
    }
    return throwError(() => this.messages);
  }
}
