import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  organizationViewSubscription: Subject<string | null> = new Subject();

  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  clearLocalStorage(): void {
    if (this.isBrowser) {
      localStorage.clear();
    }
  }

  setItemLocalStorage(key: string, value: string): void {
    if (this.isBrowser) {
      localStorage.setItem(key, value);
    }
  }

  getItemLocalStorage(key: string) {
    if (this.isBrowser) return localStorage.getItem(key);
    return null;
  }
}
