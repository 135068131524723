import { ChangeDetectorRef, Injectable } from '@angular/core';
import { RequestObj } from '../interfaces/requestObj.model';
import { Observable, OperatorFunction, catchError, map, of } from 'rxjs';
import { Apollo, MutationResult } from 'apollo-angular';
import { ErrorHandlerService } from './error-handler.service';
import { ApolloQueryResult } from '@apollo/client';
import { ToastrService } from './toastr.service';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private apollo: Apollo,
    private toastrService: ToastrService,
    private errorHandlerService: ErrorHandlerService,
    private loaderService: LoaderService,
  ) {}

  query<T, R>(requestObj: RequestObj, variables?: T): Observable<R> {
    this.loaderService.showLoader()
    return this.apollo
      .query<R, T>({
        query: requestObj.query,
        variables: variables,
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response: ApolloQueryResult<R>) => {
          this.loaderService.hideLoader(); // Hide loader after receiving the response
          if (
            requestObj.message &&
            requestObj.message.successMessage &&
            requestObj.showToastr
          ) {
            this.toastrService.showSuccessMessage(
              requestObj.message.successMessage
            );
          }
          return response.data;
        }) as OperatorFunction<Object, R>,
        catchError((error) => {
          this.loaderService.hideLoader(); // Hide loader in case of errors
          return this.errorHandlerService.handleError(
            error,
            requestObj.message || null,
            requestObj.showToastr
          );
        })
      );
  }

  mutation<T, R>(requestObj: RequestObj, variables: T): Observable<R> {
    this.loaderService.showLoader()
    return this.apollo
      .mutate<R, T>({
        mutation: requestObj.query,
        variables: variables,
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((response: MutationResult<R>) => {
          this.loaderService.hideLoader(); // Hide loader after receiving the response
            if (
              requestObj.message &&
              requestObj.message.successMessage &&
              requestObj.showToastr
            ) {
              this.toastrService.showSuccessMessage(
                requestObj.message.successMessage
              );
            }
            return response.data;
        }) as OperatorFunction<Object, R>,
        catchError((error) => {
          this.loaderService.hideLoader(); // Hide loader in case of errors
          return this.errorHandlerService.handleError(
            error,
            requestObj.message || null,
            requestObj.showToastr
          );
        })
      );
  }
}
